<template>
  <v-autocomplete
    ref="rows-switcher"
    :filter="filterProduct"
    :items="rows"
    :menu-props="{ maxHeight: 500 }"
    :search-input.sync="search"
    :value="value"
    append-icon="mdi-chevron-down"
    autocomplete="off"
    class="v-autocomplete__rows-switcher"
    color="darkGrey"
    item-value="id"
    flat
    hide-details
    return-object
    solo
    @blur="search = null"
    @change="search = null"
    @input="$emit('input', $event)">
    <template #item="{ item, $on, $attrs }">
      <span
        v-bind="$attrs"
        v-on="$on">
        <ProductSwitcherItem :item="truncatedItem(item)" />
      </span>
    </template>
    <template #selection="{ item }">
      <ProductSwitcherItem
        v-if="!search || search === ''"
        :item="truncatedItem(item)" />
    </template>
  </v-autocomplete>
</template>

<script>
import ProductSwitcherItem from './ProductSwitcherItem';

const MAX_CODE_LENGTH = 45;

export default {
  name: 'ProductSwitcher',
  components: {
    ProductSwitcherItem,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: '',
  }),
  methods: {
    filterProduct(item, queryText) {
      const { model = '', type = '' } = item;
      return model.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || type.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    truncatedItem(item) {
      const code = item?.productCode;
      if (!code || typeof(code) !== 'string' || code.length < MAX_CODE_LENGTH) return item;

      return {
        ...item,
        productCode: code.substring(0, 45) + '...',
      };
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item {
  min-height: 4rem;
  margin-bottom: 1.25rem;
}
::v-deep .v-list-item:last-child {
  margin-bottom: 0;
}
</style>