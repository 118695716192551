<template functional>
  <div class="d-flex flex-column gap-1">
    <span class="green--text fs-14 font-fam-poppins text-uppercase">{{ props.item.type || '-' }}</span>
    <span class="black--text fs-18 font-fam-poppins text-capitalize">
      {{ props.item.model && props.item.productCode
        ? `${props.item.model} ${props.item.productCode}`
        : props.item.model || props.item.productCode || '---' }}
    </span>
    <span class="darkGrey--text fs-10 text-uppercase">{{ props.item.manufacturer || '-' }}</span>
  </div>
</template>
<script>
export default {
  functional: true,
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>