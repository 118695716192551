import { kebabCase } from 'lodash';
const renderingItemsWithDataTest = ({ items, arg, before = '', after = '' }) =>{
  const newArray = items.map(item => ({
    ...item,
    dataTest: kebabCase(before + item[arg] + after)
      .replace(/-/g, '_')
      .toLowerCase(),
  }));
  return newArray;
};
export default renderingItemsWithDataTest;
