<template>
  <div class="product-info">
    <div
      v-if="!hideSwitcher"
      class="projects-form-list projects-form__product_switcher">
      <ProductSwitcher
        v-model="selected"
        :rows="rows" />
    </div>

    <!-- Iterate over the filtered header items -->
    <div
      v-for="(headerItem, i) in filterHeadersByFlexItem"
      :key="`rowId-${rowId}-${i}`"
      class="projects-form-list">
      <ProductCell
        v-if="headerItem.column.type"
        :header="headerItem"
        :cell-customizations="cellCustomizations"
        :disabled-edit-mode="disabledEditMode"
        :expanded-row="!showPensil(headerItem.column.type)"
        :should-expand-height="isAsideHeader(headerItem.text)"
        :item="item"
        :style="{ justifyContent: headerItem.align === 'right' ? 'flex-end' : 'inherit' }"
        class="pa-0"
        view="projects-product-expanded"
        open-link-in-new-tab
        @saveFileProcess="saveFileProcess"
        v-on="$listeners">
        <template #cellActions>
          <!-- Comment badge for the headerItem item -->
          <ProjectDetailsCommentBadge
            :class="[
              !getCellPointerCommentAmount(headerItem.value) ? 'v-badge__expanded-hide' : '',
            ]"
            :data-test="headerItem.dataTest"
            :item="{
              resolvedComments: true,
              ...getCellPointerAmount(headerItem.value),
            }"
            class="v-badge__expanded badged-comment"
            @click="setCellPointer(headerItem.value)" />
        </template>
      </ProductCell>
    </div>
  </div>
</template>
<script>
import {
  mapGetters, mapState, mapMutations,
} from 'vuex';

import { TYPE_READONLY } from '@/constants';

import ProductCell from '@/components/Product/ProductCell';
import ProductSwitcher from './ProductSwitcher';
import ProjectDetailsCommentBadge from '@/components/ProjectDetails/ProjectDetailsCommentBadge';

import { isAsideHeaderName } from '@/utils/productHeaders';
import renderingItemsWithDataTest from '@/utils/renderingItemsWithDataTest';

export default {
  name: 'ProductCellProjectsFormList',
  components: {
    ProductCell,
    ProductSwitcher,
    ProjectDetailsCommentBadge,
  },
  props: {
    cellCustomizations: {
      type: Object,
      default: () => {},
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({
      }),
    },
    view: {
      type: String,
      default: 'productCreate',
      validator: (value) => {
        return ['productCreate', ''].includes(value);
      },
    },
    hideSwitcher: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      rows: 'ScheduleRows/mappedDataRows',
    }),
    ...mapGetters(['userId']),
    ...mapState('Comments', ['commentCellPointers']),
    groupItems() {
      return [{
        items: ['Image', 'Line Drawing'],
      }];
    },
    filterNamesGroupItems() {
      return this.groupItems.reduce((result, { items }) => {
        return [...result, ...items];
      }, []);
    },
    filterHeadersByFlexItem() {
      const { renderingItemsWithDataTest: headers } = this;
      return headers;
    },
    renderingItemsWithDataTest() {
      return renderingItemsWithDataTest({
        items: this.headers, arg: 'text', before: 'row_comments_', after: '_category',
      });
    },
    rowId() {
      const { id } = this.item || {
      };
      return id;
    },
    selected: {
      get() {
        return this.rows.find(el => this.item.id === el.id);
      },
      set(val) {
        this.changeProductModalVariant(TYPE_READONLY);
        val && this.setModalRowId(val.id);
      },
    },
  },
  methods: {
    ...mapMutations({
      setCellPointer: 'ProjectDetailsTableSchedule/setCellPointer',
      setModalRowId: 'ProjectDetailsTableSchedule/setModalRowId',
      changeProductModalVariant: 'Collections/changeProductModalVariant',
    }),
    saveFileProcess(e = false) {
      this.$emit('saveFileProcess', e);
    },
    showPensil(type) {
      return ['DATE', 'ARRAY_AIRTABLE_IMAGE', 'FIELD_PRODUCT_REFERENCE', 'FIELD_PRODUCT_EQUAL', 'COMBOBOX', 'DROPDOWN'].includes(type);
    },
    getCellPointerAmount(name) {
      const cellPointerObj = this.commentCellPointers.find(pointer => pointer.name === name);
      return cellPointerObj ? {
        commentsAmount: cellPointerObj.amount,
        unresolved: cellPointerObj.unresolved,
      } : {
      };
    },
    getCellPointerCommentAmount(name) {
      return this.getCellPointerAmount(name).commentsAmount;
    },
    isAsideHeader(headerText) {
      return isAsideHeaderName(headerText);
    },
  },
};
</script>
<style lang="scss" scoped>
.product-info {
  flex-shrink: 1;
  overflow-y: auto;
  padding-block-end: 16px;

  .projects-form-list {
    padding: 10px 30px;
  }

  .projects-form__product_switcher {
    position: sticky;
    top: 0;
    z-index: 12;
    background: var(--v-white-base);
  }
}
</style>
